import React from "react";
import Text from "../../components/data-display/text";
import {Check} from "react-feather";
import data from "./about-usQuickTask.json";
import influence from "./our-influenceQucickTask.json";
import "../../components/gallery-components/index.css";
import {Grid} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useMediaQuery} from "@mui/material";
import {isQuickTaskProject, title} from "../../utils/constant.urls";
  
  
const styles: Record<string, React.CSSProperties> = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50%",
    margin: "0 auto",
    marginTop: "90px",
    marginBottom: "75px",
  },
  badges: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-evenly",
  },
  bigSpacing: {
    height: "30px",
  },
  smallSpacing: {
    height: "15px",
  },
  cover: {
    objectFit: "cover",
  },
};
const QuickTaskAboutSection = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      {" "}
      <ul style={{listStyle: "none", paddingLeft: "0px"}}>
        {data.map((c) => {
          return (
            <li key={c.title}>
              <>
                {mdDown ? (
                  <Grid container>
                    {c.image && (
                      <Grid item md={12} lg={12} xl={12}>
                        <img
                          loading={"lazy"}
                          src={c.image}
                          alt={c.title}
                          style={{
                            ...styles.cover,
                            width: "85%",
                            height: !xsDown ? "90%" : "100%",
                          }}
                        />
                      </Grid>
                    )}
                    {!c.image && !xsDown && <div style={{height: "20px"}}></div>}
                    <Grid item md={12} lg={12} xl={12}>
                      {c.title !== "" && (
                        <div>
                          <Text
                            variant={"h3"}
                            black
                            style={{
                              marginTop: smDown ? "20px" : "0px",
                              marginBottom: smDown ? "20px" : "0px",
                            }}
                          >
                            {c.title}{" "}
                          </Text>
                        </div>
                      )}
                      <Text black variant={"h6 "}>
                        {c.title2}
                      </Text>
                      <Text regular variant={"h6"} style={{maxWidth: "100%"}}>
                        {c.description}
                      </Text>
                      {/*{c.apps && (
                        <div
                          style={{
                            ...styles.badges,
                            display: "flex",
                            justifyContent: "flex-start",
                            marginTop: "20px",
                          }}
                        >
                          <a
                            href={"https://apps.apple.com/us/app/sydetasker/id1502353211"}
                            rel="noopener noreferrer"
                            target={"_blank"}
                          >
                            <img
                              loading="lazy"
                              alt="Download on the App Store"
                              src={AppleStore}
                              style={{}}
                            />
                          </a>
                          <a
                            href="https://play.google.com/store/apps/details?id=ai.unicode.sydetasker&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                            rel="noopener noreferrer"
                            target={"_blank"}
                          >
                            <img
                              loading="lazy"
                              alt="Get it on Google Play"
                              src={AppStore}
                              style={{marginLeft: "20px"}}
                            />
                          </a>
                        </div>
                      )}*/}
                    </Grid>
                    {!c.description && (
                      <>
                        <Text variant={"body1"} style={{textAlign: "center"}}>
                          Outsourcing tasks on {title} has a positive impact on everyone in the
                          surrounding community!
                        </Text>
                        <div
                          style={{
                            ...styles.smallSpacing,
                            width: "85%",
                            height: !xsDown ? "90%" : "100%",
                          }}
                        />
                        {(influence ?? []).map((str) => {
                          return (
                            <div style={{height: "auto", padding: "14px 0px"}}>
                              <Check
                                size={18}
                                style={{
                                  color: "#4caf50",
                                  verticalAlign: "middle",
                                  marginRight: "8px",
                                }}
                              />
                              <Text variant={"body1"} black component={"span"}>
                                {str.title}
                              </Text>
                              <Text
                                variant={"h6"}
                                regular
                                component={"p"}
                                style={{paddingLeft: "45px"}}
                              >
                                {str.description}
                              </Text>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </Grid>
                ) : (
                  <Grid container>
                    {!c.right && (
                      <>
                        <Grid xs={6} sm={6} lg={6}>
                          <img
                            loading={"lazy"}
                            src={c.image}
                            alt={c.title}
                            style={{
                              ...styles.cover,
                              width: "80%",
                              height: "93%",
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} lg={6} style={{paddingLeft: "30px"}}>
                          {c.title && (
                            <div>
                              <Text variant={"h3"} black>
                                {c.title}
                              </Text>

                              <div style={{height: "26px"}} />
                            </div>
                          )}
                          <Text black variant={"h6 "}>
                            {c.title2}
                          </Text>
                          <Text
                            regular
                            variant={"h6"}
                            style={{maxWidth: "600px", marginTop: xsDown ? "16px" : ""}}
                          >
                            {c.description && (
                              <>
                                <div style={{height: "9px"}} />
                                {c.description}
                              </>
                            )}
                            {/*{c.apps && (
                              <div
                                style={{
                                  ...styles.badges,
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  marginTop: "20px",
                                }}
                              >
                                <a
                                  href={"https://apps.apple.com/us/app/sydetasker/id1502353211"}
                                  rel="noopener noreferrer"
                                  target={"_blank"}
                                >
                                  <img
                                    loading="lazy"
                                    alt="Download on the App Store"
                                    src={AppleStore}
                                    style={{}}
                                  />
                                </a>
                                <a
                                  href="https://play.google.com/store/apps/details?id=ai.unicode.sydetasker&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                                  rel="noopener noreferrer"
                                  target={"_blank"}
                                >
                                  <img
                                    loading="lazy"
                                    alt="Get it on Google Play"
                                    src={AppStore}
                                    style={{marginLeft: "20px"}}
                                  />
                                </a>
                              </div>
                            )}*/}
                          </Text>
                          <div style={{...styles.smallSpacing}} />
                          {!c.description && (
                            <>
                              <Text variant={"body1"} style={{textAlign: "center"}}>
                                Outsourcing tasks on {title} has a positive impact on everyone in
                                the surrounding community!
                              </Text>
                              <div style={{...styles.smallSpacing}} />
                              {(influence ?? []).map((str) => {
                                return (
                                  <div style={{height: "auto", padding: "14px 0px"}}>
                                    <Check
                                      size={18}
                                      style={{
                                        color: "#4caf50",
                                        verticalAlign: "middle",
                                        marginRight: "8px",
                                      }}
                                    />
                                    <Text variant={"body1"} black component={"span"}>
                                      {str.title}
                                    </Text>
                                    <Text
                                      variant={"h6"}
                                      regular
                                      component={"p"}
                                      style={{paddingLeft: "45px"}}
                                    >
                                      {str.title}
                                    </Text>
                                    <Text
                                      variant={"h6"}
                                      regular
                                      component={"p"}
                                      style={{paddingLeft: "45px"}}
                                    >
                                      {str.description}
                                    </Text>
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </Grid>
                      </>
                    )}
                    {c.right && (
                      <>
                        <Grid item xs={6} sm={6} style={{}}>
                          {c.title && (
                            <div style={{paddingBottom: "0px", marginTop: xsDown ? "16px" : "0px"}}>
                              <Text variant={"h3"} black>
                                {c.title}
                              </Text>
                            </div>
                          )}
                          <Text
                            black
                            variant={"h6 "}
                            //style={{maxWidth: "570px", marginTop: xsDown ? "16px" : "30px"}}
                          >
                            {c.title2}
                          </Text>
                          <Text
                            regular
                            variant={"h6"}
                            style={{maxWidth: "570px", marginTop: xsDown ? "16px" : "30px"}}
                          >
                            {c.description}
                          </Text>
                        </Grid>
                        <Grid xs={6} sm={6}>
                          <img
                            loading={"lazy"}
                            src={c.image}
                            alt={c.title}
                            style={{
                              ...styles.cover,
                              width: "90%",
                              height: "90%",
                            }}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                )}
              </>

              {c.title !== "" &&
                c.title !== "Our Positive Influence " &&
                c.title !== "Our Beginning" &&
                !xsDown && (
                  <div
                    style={{
                      height: "200px",
                      width: "100%",
                    }}
                  />
                )}

              {c.title !== "" && c.title === "Our Beginning" && !xsDown && (
                <div
                  style={{
                    height: "100px",
                    width: "100%",
                  }}
                />
              )}
              {xsDown && (
                <div
                  style={{
                    height: "20px",
                    width: "100%",
                  }}
                />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default QuickTaskAboutSection;
